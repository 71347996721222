<template>
    <div>
        <img src="@/assets/logo_rect.jpg" alt="PLint-sites logo rectangular">
        <div id="links" v-if="!authenticated">
            <router-link :to="{ name: 'Login' }" title="Login page" exact class="btn btn-primary" style="width: 100%;"><i class="fa fa-lock"></i> Login</router-link>
        </div>
        <div v-else>
            <TargetBar :score="todos[0]" :target="todos[1]" title="Todos vandaag"/>
            <TargetBar :score="parseFloat(hours.day)" :target="6" :decimals="1" title="Uren vandaag"/>
            <TargetBar :score="weekRevenue" :target="1400" title="Omzet deze week"/>

            <button v-if="notificationsSupported" @click="toggleSubscription" :disabled="buttonDisabled">{{ (notificationsEnabled ? 'Disable' : 'Enable') }} notifications ></button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import dayjs from 'dayjs'
import axios from '@/libraries/fetcher'
import TargetBar from '@/components/TargetBar'

export default {
    name: 'Home',
    components: {TargetBar},
    data() {
        return {
            today: dayjs().format('YYYY-MM-DD'),
            notificationsSupported: false,
            notificationsEnabled: false,
            isPushEnabled: false,
            buttonDisabled: false,
            swreg: null,
            subscription: null,
        }
    },
    computed: {
        ...mapGetters('user', ['authenticated', 'userId']),
        ...mapState('todos', ['digest']),
        ...mapState('dashboard', ['hours']),
        ...mapGetters('dashboard', ['revenue']),
        todos() {
            if (this.digest.hasOwnProperty(this.today)) {
                const digestAll = this.digest[this.today]
                .filter(todo => todo.customer_id !== 53)

                const unfinished = digestAll.filter(todo => todo.finished === 0)
                .sort(function(todo1, todo2) {
                    if (todo1.customer_id > todo2.customer_id) return 1
                    if (todo1.customer_id === todo2.customer_id) return 0
                    return -1
                })

                const finished = digestAll.filter(todo => todo.finished === 1)
                .sort(function(todo1, todo2) {
                    if (todo1.customer_id > todo2.customer_id) return 1
                    if (todo1.customer_id === todo2.customer_id) return 0
                    return -1
                })

                return [finished.length, unfinished.length + finished.length]
            }
            return [0,0]
        },
        weekRevenue() {
            return this.revenue.week[1]
        },
    },
    methods: {
        ...mapActions('todos', ['getDigest']),
        ...mapActions('dashboard', ['getData']),

        // ---
        // Main functions called from template or lifecycle hooks
        // ---
        checkSubscription() {
            if (this.notificationsSupported) {
                this.getSWRegistration()
                .then(this.getSubscription)
                .then(this.handleSubscription)
            } else {
                alert('Sorry, your browser does not support notifications...')
            }
        },
        toggleSubscription(e) {
            // console.log('event', e);
            // console.log('target', e.target);
            if (this.notificationsSupported) {

                // toggle notificationsEnabled
                this.notificationsEnabled = !this.notificationsEnabled

                // check for checked or unchecked
                // console.log('checked', this.notificationsEnabled);
                if (this.notificationsEnabled) {
                    // we hebben de subscription stopgezet, we beginnen dus helemaal opnieuw
                    //  1] toegang vragen notification API
                    //  2] toevoegen bij PushManager
                    //  3] opslaan in DB via API

                    console.log('ask for permission');

                    Notification.requestPermission()
                    .then(this.createSubscription) // create new subscription...
                    .then(this.storeSubscription)  // and store it on the server
                    .then(this.displayConfirmNotification)  // end with showing a notification on the screen
                    .then(() => this.isPushEnabled = true)
                } else {

                    console.log('clean up notifications');
                    // zet de subscription op stop
                    //  1] verwijderen bij PushManager, call method 'unsubscribe' on active sub; // returns a promise
                    //  2] verwijderen uit database via API
                    //  3] this.isPushEnabled = false
                    //  4] permission notifications API revoken => navigator.permissions.revoke('notifications'); // returns a promise

                    if (this.subscription !== null) {
                        console.log('Delete subscription in DB, browser vendor etc.');
                        this.deleteSubscription(this.subscription)
                        .then(() => this.unsubscribeSubscription(this.subscription))
                        .then(() => this.isPushEnabled = false)
                    } else {
                        console.log('There is no subscription so we don\'t do anything');
                    }
                }
            } else {
                alert('Sorry, your browser does not support notifications...')
            }
        },

        // ---
        // Actions: storeSub and deleteSub
        // ---
        storeSubscription(subscription) {
            console.log('sub created', subscription);
            this.subscription = subscription
            return axios.post(`${process.env.VUE_APP_API_PATH}/api/subscription/store/${this.userId}`, {subscription})
        },

        deleteSubscription(subscription) {
            return axios.post(`${process.env.VUE_APP_API_PATH}/api/subscription/delete`, subscription)
        },

        // ---
        // Helpers for along the way
        // ---
        getSWRegistration() {
            console.log('ask for active service worker registration');
            return navigator.serviceWorker.ready // returns a Promise, the active SW registration
        },
        getSubscription(swreg) {
            // add swreg to data we need it in createSubscription
            this.swreg = swreg

            // ask the push manager for the available subscription (null if nothing found)
            // this will return a promise which we return directly to be able to chain another .then
            // There will be only one sub for this device, browser combination
            console.log('ask for available subscription');
            return swreg.pushManager.getSubscription()
        },
        handleSubscription(sub) {
            console.log('sub found', sub);
            if (sub !== null) {
                this.notificationsEnabled = true
                this.isPushEnabled = true
                this.subscription = sub
            }
        },
        createSubscription() {
            console.log('create new subscription for this browser on this device');
            // create new subscription for this browser on this device
            const vapidPublicKey = process.env.VUE_APP_VAPID_PUBLIC_KEY
            const convertedVapidPublicKey = this.urlBase64ToUint8Array(vapidPublicKey)
            // return the subscription promise, we chain another then where we can send it to the server
            return this.swreg.pushManager.subscribe({
                userVisibleOnly: true,
                // This is for security. Inside the PLintApp API, we need to do something with the VAPID_PRIVATE_KEY
                // that you can find in .env to make this work in the end
                applicationServerKey: convertedVapidPublicKey
            })
        },
        unsubscribeSubscription(subscription) {
            return subscription.unsubscribe()
        },
        displayConfirmNotification() {
            return this.swreg.showNotification('Thank you for subscribing', {
                body: 'You can change your settings any time on the settings page',
                icon: '/img/icons/android-chrome-192x192.png',
                image: '/img/oops.jpg',
                vibrate: [300, 200, 300],
                badge: '/img/icons/plint-badge-96x96.png',
                actions: [
                    { action: 'settings', title: 'Settings', icon: '/img/icons/android-chrome-192x192.png'},
                    { action: 'confirm', title: 'Okay', icon: '/img/icons/android-chrome-192x192.png'},
                    { action: 'cancel', title: 'Cancel', icon: '/img/icons/android-chrome-192x192.png'}
                ],
            })
        },
        urlBase64ToUint8Array(base64String) {
            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
                .replace(/\-/g, '+')
                .replace(/_/g, '/');

            const rawData = window.atob(base64);
            let outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        },
    },
    created() {
        if ('Notification' in window && 'serviceWorker' in navigator) {
            this.notificationsSupported = true
        }
    },
    mounted() {
        if (this.authenticated) {
            this.getDigest()
            this.getData()
        }

        this.checkSubscription()
    },
};
</script>

<style scoped lang="less">
#links {
    #quick-links {
        margin-bottom: 24px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 5px;
    }

    #projects, #misc {
        margin-bottom: 24px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        a {
            color: @textcolor
        }
    }

    .block-header {
        padding-left: 12px;
    }
}

img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6px;
    margin-top: -24px;
    width: 100%;
}

button {
    margin-top: 24px;
    background: #1da025;
    border: none;
    color: white;
    height: 48px;
    padding: 12px 0;
    width: 100%;
}
</style>
