import axios from '@/libraries/fetcher'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    postData(context, data) {
      return axios.post(`/api/hour`, data)
    },
  },
}
