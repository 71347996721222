import axios from '@/libraries/fetcher'

export default {
  namespaced: true,
  state: {
    dataAvailable: false,
    rawRevenue: [],
    todayString: '',
    hours: {
      day: 0,
      week: 0,
      month: 0,
      ytd: 0,
    },
    hoursForCustomers: null,
  },
  getters: {
    revenue(state) {
      return {
        day: mungeRevenue(state.rawRevenue, 'daily'),
        week: mungeRevenue(state.rawRevenue, 'weekly'),
        month: mungeRevenue(state.rawRevenue, 'monthly'),
        year: mungeRevenue(state.rawRevenue, 'yearly'),
      }
    },
  },
  mutations: {
    dashboard(state, data) {
      state.dataAvailable = true
      state.hours = data.hours,
      state.hoursForCustomers = data.hoursForCustomers
      state.todayString = data.todayString
      state.rawRevenue = data.revenue
    },
  },
  actions: {
    getData({commit}) {
      return axios.get(`/api/dashboard`)
      .then(({data}) => commit('dashboard', data))
    },
  },
}

export const mungeRevenue = (response, period) => {
  return revenueKeys[period].map(key => revenueReducer(response, key))
}

export const revenueReducer = (response, key) => {
  return response.reduce((currentValue, customer) => {
    return currentValue + parseFloat(customer[key])
  }, 0)
}

export const revenueKeys = {
  daily: [
    'dailyRevenue', 
    'dailyRevenueCompany', 
  ],
  weekly: [
    'weeklyRevenue', 
    'weeklyRevenueCompany',
  ],
  monthly: [
    'monthlyRevenue', 
    'monthlyRevenueCompany', 
  ],
  yearly: [
    'yearlyRevenue', 
    'yearlyRevenueCompany',
  ],
}

// export {mungeRevenue, revenueReducer, revenueKeys}