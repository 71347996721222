import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import store from '@/store'

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['user/authenticated']) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters['user/authenticated']) {
    next()
    return
  }
  next(`/login?redirect=${to.path}`)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    beforeEnter: ifNotAuthenticated, // no need to come here when already loggedin
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    beforeEnter: ifAuthenticated, // only allowed when loggedin
  },
  {
    path: '/todo/digest/:date?',
    name: 'TodoDigest',
    component: () => import(/* webpackChunkName: "todo-digest" */ '../views/TodoDigest.vue'),
    beforeEnter: ifAuthenticated, // only allowed when loggedin
  },
  {
    path: '/todo/:type/:id/:date',
    name: 'Todo',
    component: () => import(/* webpackChunkName: "single-todo" */ '../views/Todo.vue'),
    beforeEnter: ifAuthenticated, // only allowed when loggedin
  },
  // create hour record
  // {
  //   path: '/hours/create',
  //   name: 'CreateHourRecord',
  //   component: () => import(/* webpackChunkName: "create-hour-record" */ '../views/CreateHourRecord.vue'),
  //   beforeEnter: ifAuthenticated, // only allowed when loggedin
  // },
  {
    path: '/add-new-item',
    name: 'AddNewItem',
    component: () => import(/* webpackChunkName: "add-new-item" */ '../views/AddNewItem.vue'),
    beforeEnter: ifAuthenticated, // only allowed when loggedin
  },
  // mail an item (photo, voice memo)
  {
    path: '/mail-item',
    name: 'SendItemToMail',
    component: () => import(/* webpackChunkName: "send-item-mail" */ '../views/SendItemToMail.vue'),
    beforeEnter: ifAuthenticated, // only allowed when loggedin
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
