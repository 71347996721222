<template>
    <div class="target-bar-container">
        <p>{{title}}</p>
        <div class="target-bar">
            <div class="inner" :style="targetBarWidth"><span>{{ currentScoreInCorrectDecimals }}/{{ target.toFixed(0) }}</span></div>
            <i v-if="score >= target" class="fa fa-check-circle-o"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TargetBar',
    props: {
        score: {
            required: true,
            type: Number,
        },
        target: {
            required: true,
            type: Number,
        },
        title: {
            required: true,
            type: String,
        },
        decimals: {
            required: false,
            type: Number,
        },
    },
    computed: {
        currentScoreInCorrectDecimals() {
            if (this.decimals) {
                return this.score.toFixed(this.decimals)
            }
            return this.score.toFixed(0)
        },
        barWidth() {
            const width = this.score / this.target * 100
            if (width > 100) {
                return '100'
            }
            return width.toFixed(0)
        },
        targetBarWidth() {
            return {
                width: `${this.barWidth}%`
            }
        },
    },
}
</script>

<style lang="less" scoped>
.target-bar-container {
    margin-bottom: 16px;
    .target-bar {
        width: 100%;
        height: 54px;
        border: 3px solid @secondary;
        position: relative;

        .inner {
            background: @primary;
            height: 48px;
            color: @secondary;
            font-size: 22px;
            line-height: 48px;

            span {
                padding-left: 10px;
            }
        }

        i.fa-check-circle-o {
            color: @secondary;
            font-size: 22px;
            position: absolute;
            right: 10px;
            top: 13px;
        }
    }

    p {
        margin-bottom: 6px;
    }
}
</style>