<template>
    <header>
        <h2>
            <router-link :to="{ name: 'Home' }" title="Homepage" exact><i class="fa fa-home"></i></router-link>
            <router-link :to="{ name: 'Dashboard' }" title="Your dashboard" exact><i class="fa fa-tachometer"></i></router-link>
            <router-link :to="{ name: 'TodoDigest' }" title="Todo digest" exact><i class="fa fa-list"></i></router-link>
            <router-link :to="{ name: 'AddNewItem' }" title="Create new item" exact><i class="fa fa-plus" aria-hidden="true"></i></router-link>
            <router-link :to="{ name: 'SendItemToMail' }" title="Send item to mail" exact><i class="fa fa-paper-plane" aria-hidden="true"></i></router-link>
            <span id="logout" v-if="authenticated" @click="logoutAndRedirect"><i class="fa fa-sign-out" aria-hidden="true"></i></span>
        </h2>
    </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'HeaderComp',
    computed: {
        ...mapGetters('user', ['authenticated']),
    },
    methods: {
        ...mapActions(['logout']),
        logoutAndRedirect() {
            this.logout()
            .then(() => {
                // only navigate to home when current route is not home
                if (this.$route.name !== 'Home') {
                    this.$router.push('/')
                }
            })
        }
    },
};
</script>

<style scoped lang="less">
header {
    background: @primary;
    color: #fff;
    margin-bottom: 48px;
    height: 72px;
    padding: 0 30px;

    h2 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        font-size: 23px;
        line-height: 34px;
        padding-top: 19px;
        padding-bottom: 19px;
        margin-bottom: 0;

        a {
            color: white;
            &:hover {
                text-decoration: none;
            }
        }

        #logout {
            justify-self: end;
            &:hover {
                cursor: pointer;
            }
        }
    }
}
</style>
