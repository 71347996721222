import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios from '@/libraries/fetcher'

// import modules
import user from './modules/user'
import dashboard from './modules/dashboard'
import hour from './modules/hour'
import todos from './modules/todos'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {},
  mutations: {
    // root mutations to reset state for ALL modules
    logout(state) {
      state.user.user = null
      state.user.isLoggedIn = false

      state.dashboard.dataAvailable = false
      state.dashboard.hours = {
        day: 0,
        week: 0,
        month: 0,
        ytd: 0,
      },
      state.dashboard.hoursForCustomers = null
      state.dashboard.rawRevenue = []
      state.dashboard.todayString = ''

      state.todos.dataAvailable = false
      state.todos.digest = []
      state.todos.digestTimestamp = null
    },
  },
  actions: {
    logout({commit}) {
      return axios.post('/logout')
      .then(() => commit('logout'))
    },
  },
  modules: { 
    user,
    dashboard,
    hour,
    todos
  },
  plugins: [vuexLocal.plugin]
})
