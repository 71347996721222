<template>
  <div id="app">
    <header-comp></header-comp>
    <button id="refresh-button" class="btn-secondary" v-if="updateExists" @click="refreshApp"><i class="fa fa-hand-pointer-o" aria-hidden="true"></i> New version, tap here to update!</button>
    <main :style="extraPadding">
        <router-view/>
    </main>
  </div>
</template>

<script>
import HeaderComp from '@/components/HeaderComp.vue'

export default {
    name: 'App',
    components: {HeaderComp},
    data() {
        return {
            refreshing: false,
            registration: null,
            updateExists: false,
        }
    },
    computed: {
        extraPadding() {
            if (this.updateExists) {
                return {
                    paddingBottom: '72px'
                }
            }
            return {}
        },
    },
    methods: {
        showRefreshUI(e) {
            this.registration = e.detail
            this.updateExists = true  
        },
        refreshApp() {
            this.updateExists = false
            if (!this.registration || !this.registration.waiting) return
            // send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage('skipWaiting')
        },
    },
    created() {
        if (process.env.NODE_ENV === 'production') {
            document.addEventListener('swUpdated', this.showRefreshUI, {once: true})
                                        
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing) return
                this.refreshing = true
                // Here the actual reload of the page occurs
                window.location.reload()
            })
        }
    },
};
</script>

<style lang="less">
body {
    margin: 0;
}

#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
}

main {
    padding: 0 24px 24px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
}

.block-header {
    padding: 12px 0 12px 6px;
    margin: 0;
    height: 48px;
    background: @secondary;
    color: #fff;
    font-weight: 700;
    text-align: left;
}

.form-control {
    color: @textcolor;
    border-color: @background1;

    &:focus {
        border-color: @background1;
        box-shadow: 0 0 0 0.2rem @background2;
    }
}

.btn.btn-primary {
    background: @primary;
    border-radius: 0;
    border-color: @background1;
    height: 48px;
    padding: 12px 0px;

    &.btn-multi-dark {
        background: @background1;
    }

    &.btn-multi-light {
        background: @background2;
    }

    &.btn-inverse {
        background: white;
        border-color: @primary;
        border-width: 2px;
        color: @primary;
    }

    &:hover, &:active, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active {
        background-color: @secondary !important;
        border-color: @secondary !important;
    }

    &:focus {
        outline-color: #f7d6d4;
        box-shadow: 0 0 0 0.2rem #f7d6d4;
    }

    &:disabled {
        background: @background1;
        border-color: @background2;
        color: @textcolor;
    }
}

.btn-secondary {
    background: @secondary;
    border-radius: 0;
    height: 48px;
    padding: 12px 24px;

    &:hover {
        background: @secondary;
        border-color: @secondary;
    }

    &:focus {
        outline-color: #f7d6d4;
        box-shadow: 0 0 0 0.2rem #f7d6d4;
    }

    &:disabled {
        background: @background1;
        border-color: @background2;
        color: @textcolor;
    }
}

table {
    margin-top: 48px;
    margin-bottom: 48px;
    font-size: 16px;
    text-align: left;
    color: @textcolor;

    thead th {
        background: @secondary;
        border-bottom: 1px solid @background1;
        border-top: 1px solid @background1;
        border-color: @background1;
        color: #fff;
    }

    &.table-striped tbody tr:nth-of-type(even) {
        background: @background1;
    }

    &.table-striped tbody tr:nth-of-type(odd) {
        background: @background2;
    }

    thead th, tbody td {
        width: 50%;
        height: 48px;
        padding: 0 0 0 6px;
    }

    &.table td, &.table th {
        vertical-align: middle;
    }

    td a {
        text-decoration: underline;
        color: @textcolor;
    }

    i.fa-star {
        font-size: 12px;
        position: relative;
        top: -4px;
        color: @secondary;
        &.white {
            color: white;
        }
    }
}

#refresh-button {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10;
}
</style>
