import Vue from 'vue'
import VueTippy from 'vue-tippy'
import VueFormulate from '@braid/vue-formulate';
import { nl } from '@braid/vue-formulate-i18n';

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import '@/less/vue-tippy-overwrites.less';

Vue.config.productionTip = false

Vue.use(VueTippy, {
    delay: [150, 0],
    placement: 'top-start',
    animation: 'fade',
    arrow: true,
    maxWidth: (window.innerWidth < 768 ? '250px' : '315px'),
    zIndex: 99999,
    theme: 'plintapp',
})
Vue.use(VueFormulate, {
    plugins: [nl],
    locale: 'nl',
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
