import axios from '@/libraries/fetcher'

export default {
    namespaced: true,
    state: {
        dataAvailable: false,
        digest: [],
        digestTimestamp: null,
    },
    getters: {},
    mutations: {
        digest(state, {items, todayString}) {
            state.dataAvailable = true
            state.digest = items
            state.digestTimestamp = todayString
        },
        markTodoFinished(state, {id, type, dateString}) {
            // put the list in a temp variable
            const currentTodoList = state.digest[dateString]
            // find the index of the finished item
            let index = currentTodoList.findIndex(item => item.id === id && item.baseroute === type)
            let item = currentTodoList[index]
            // update item's finished property
            item.finished = 1
            // remove the finished item from the list and add the updated item
            currentTodoList.splice(index, 1, item)
            // set todo.digest
            state.digest[dateString] = currentTodoList
        },
        removeFromDigest(state, {id, type, date}) {
            let index = state.digest[date].findIndex(item => item.id === id && item.baseroute === type)
            state.digest[date].splice(index, 1)
        },
    },
    actions: {
        getDigest({commit}) {
            return axios.get(`${process.env.VUE_APP_API_PATH}/api/todoitems/digest`)
            .then(({data}) => commit('digest', data))
        },
        getTodo({commit}, {id, type}) {
            return axios.get(`${process.env.VUE_APP_API_PATH}/api/${type}/${id}`)
            .then(({data: todo}) => todo)
        },
        finishTodo({commit}, {id, type, dateString}) {
            return axios.post(`${process.env.VUE_APP_API_PATH}/api/${type}/${id}/finish/${dateString}`)
            .then(() => commit('markTodoFinished', {id, type, dateString}))
        },
        updateTodoDeadline({commit}, {id, type, deadline}) {
            return axios.post(`${process.env.VUE_APP_API_PATH}/api/${type}/${id}/update-deadline`, {deadline})
        },
        deleteTodo({commit}, {id, type, date}) {
            return axios.post(`${process.env.VUE_APP_API_PATH}/api/${type}/${id}/delete`)
            .then(() => commit('removeFromDigest', {id, type, date}))
        },
        deleteSingleDay({commit}, {id, type, date}) {
            return axios.post(`${process.env.VUE_APP_API_PATH}/api/recurring-todoitem/${id}/delete-single-day`, {
                date
            })
            .then(() => commit('removeFromDigest', {id, type, date}))
        },
        convertToSingle({commit}, {currentDate, newDate, id}) {
            return axios.post(`${process.env.VUE_APP_API_PATH}/api/recurring-todoitem/${id}/convert-to-single`, {
                old_date: currentDate,
                new_date: newDate
            })
        },
        getCustomerAndProjectList() {
            return axios.get(`${process.env.VUE_APP_API_PATH}/api/todoitems/customers-and-projects`)
        },
        storeTodo(context, data) {
            return axios.post(`${process.env.VUE_APP_API_PATH}/api/todoitem`, data)
        },
    },
}
