import axios from '@/libraries/fetcher'

export default {
    namespaced: true,
    state: {
        isLoggedIn: false,
        user: null,
    },
    getters: {
        authenticated(state) {
            return state.isLoggedIn
        },
        userName(state) {
            return state.user ? state.user.name.toUpperCase() : ''
        },
        userId(state) {
            return state.user ? state.user.id : null
        },
    },
    mutations: {
        login(state) {
            state.isLoggedIn = true
        },
        setUser(state, user) {
            state.user = user
        },
    },
    actions: {
        async login({commit}, {email, password}) {
            await axios.get(`/sanctum/csrf-cookie`)
            await axios.post('/login', {
                email,
                password,
            })
            commit('login')
            const {data: user} = await axios.get(`/api/user`)
            commit('setUser', user)
        },      
    },
}