import axios from 'axios'
import router from '../router'
import store from '../store'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_PATH

// add response interceptor to redirect to login when session timed out
axios.interceptors.response.use(function(response) {
    return response
}, function(error) {
    if (error.response.status === 401) {
        // clean up store: make sure the Vue App is aware we are unauthenticated, we need access to the store here
        store.commit('logout')
        // use the router to go to the correct route afterwards
        router.push('/login')
    }
    return Promise.reject(error)
})

export default axios
